<template>
  <Browse
    :columns="[
      'appointment_type',
      'customer',
      'sales_person',
      'date_time',
      'description',
      'appointment_status',
      'note',
    ]"
    :formats="{
      date_time: 'DateTime',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="appointments"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
